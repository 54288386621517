.rich-text-content p:empty {
  margin: 16px 0;
}

.rich-text-content p {
  margin: 0;
  padding: 0;
}

.rich-text-content h1,
.rich-text-content h2,
.rich-text-content h3 {
  margin: 0;
  padding: 0;
}

.rich-text-content strong {
  font-weight: bold;
}

.rich-text-content em {
  font-style: italic;
}
